$(document).ready(function () {
    if( $("div.passepartout").hasClass("closed") ){
        $("body").addClass("closed");
    }
    
    
    $(function(){
        $(".werkliste table").find("th").eq(2).click();
    });

    $(".werkliste table").stupidtable();
    
    

    var slider = $("#lightSlider").lightSlider({
        item: 1,
        loop: true,
        keyPress: true,
        onBeforeSlide: function (el) {
            $('#counter-current').text(el.getCurrentSlideCount());
            
            if(el.getCurrentSlideCount() == el.getTotalSlideCount()){
                $(".lSAction").addClass("makeitsmall");
            } else {
                $(".lSAction").removeClass("makeitsmall");
            }
        }
    });
    

    if (slider.getTotalSlideCount) {

        $('#counter-total').text(slider.getTotalSlideCount());

        $('#slide-info').click(function () {
            slider.goToSlide(slider.getTotalSlideCount());
        });
    }





    $("button#menu").click(function () {
        $("nav").toggleClass("open");
        $(this).toggleClass("open");
    });

    $("div.passepartout.closed").click(function (event) {
        $("body").removeClass("closed");
        
        $("div.crosses").fadeOut(200);

        event.preventDefault();
        $(".passepartout").removeClass("closed");

        history.pushState(null, null, "bauten");
    });


    
    
//    $(".lSPrev").mousemove(function () {
//
//        $("#cursor").show();
//    }, function () {
//        $("#cursor").hide(); //Hide tooltip
//    })
//
//    $('.lSPrev').mousemove(function (e) {
//        $("#cursor").css({
//            left: e.pageX - 120,
//            top: e.pageY - 80
//        });
//    });

    
    
    

});